import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StandardModal from '../modal/variants/StandardModal';
import { ModalContext } from '../../../contexts/ModalContext';
import DataExportService from '../../../services/DataExportService';
import { useItemSelection } from '../../../contexts/select-items/SelectItemsContext';
import { useProcessesHub } from '../../../contexts/signalR/ProcessesContext';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import Button, { ButtonType } from '../form-control/Button';
import { EventSystem } from '../../../events/EventSystem';
import { useNavigate } from 'react-router-dom';
import { FileUtils } from '../../../utils/FileUtils';
import DateUtils from '../../../utils/DateUtils';
import DataExportChooseDetails from './DataExportChooseDetails';
import DataExportChooseReferences from './DataExportChooseReferences';
import DataExportChooseAttachments from './DataExportChooseAttachments';
import DataExportSettings from './DataExportSettings';
import PageLoader from '../page-loader/PageLoader';
import { DataExportOptions } from '../../../models/DataExport';
import { RiskStatus, riskStatusOptions } from '../../../models/Risk';
import { ApiResponse } from '../../../models/ApiResponse';
import { FormListItem } from '../../../models/Form';
import useDebounce from '../../../hooks/useDebounce';
import ClientFormService from '../../../services/ClientFormService';
import { FormReferenceDirection } from '../../../models/ClientForm';
import { useCurrentClient } from '../../../global-state/Clients';

type Props = {
  open: boolean;
  onClose: () => void;
  fileName?: string;
  forceMode?: ExportModes;
};

const Modes = ['pdf', 'excel'] as const;
export type ExportModes = (typeof Modes)[number];

type ModalStep = 'details' | 'references' | 'attachments' | 'settings';

const DataExportModal: FC<Props> = (props) => {
  const { open, onClose, fileName, forceMode } = props;
  const { t } = useTranslation(['data-import-export', 'risk']);
  const client = useCurrentClient((x) => x.value);
  const { selection, space, downloadPreferences } = useItemSelection();
  const { source, selectedAll, excludedIds, filter } = selection;

  const [exportCoverPage, setExportCoverPage] = useState(downloadPreferences ? downloadPreferences.includeCoverPage : true);
  const [evidenceReport, setEvidenceReport] = useState(true);
  const [distributionLogs, setDistributionLogs] = useState(false);
  const [exportReferences, setExportReferences] = useState(downloadPreferences ? downloadPreferences.includeReferences : true);
  const [exportAttachments, setExportAttachments] = useState(downloadPreferences ? downloadPreferences.includeAttachments : true);
  const [exportRisk, setExportRisk] = useState(true);
  const toaster = useToasts();
  const { listenToProcess } = useProcessesHub();
  const navigate = useNavigate();
  const [fileNameInternal, setFileNameInternal] = useState(fileName ?? '');
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const excludedReferences = useRef<Record<string, Set<string>>>({});
  const excludedAttachments = useRef<Record<string, Set<string>>>({});
  const [dataExportOptions, setDataExportOptions] = useState<DataExportOptions>();
  const [showRiskOwner, setShowRiskOwner] = useState(true);
  const [riskStatuses, setRiskStatuses] = useState(riskStatusOptions(t).map((x) => ({ id: x.value, text: x.text, value: true })));

  const currentClient = useCurrentClient((x) => x.value);
  const [isLoading, setIsLoading] = useState(false);
  const [pagedData, setPagedData] = useState<ApiResponse<FormListItem[]> | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 700);

  useEffect(() => {
    setPageNumber(1);
  }, [debouncedSearchValue]);

  const includedIds = useMemo(() => {
    return selection.selectedAll
      ? undefined
      : Object.entries(selection.selectionStatus)
          .filter(([_, x]) => x)
          .map(([x, _]) => x);
  }, [selection.selectedAll, selection.selectionStatus]);

  const loadPage = useCallback(
    (pageNumber: number) => {
      setIsLoading(true);
      ClientFormService.getFormsPaged(currentClient!.id, {
        ...selection.filter,
        pageNumber,
        includedFormIds: includedIds,
        excludedFormIds: selection.excludedIds,
        countReferences: FormReferenceDirection.Source,
        countAttachments: true,
        title: ((selection.filter.title ?? '') + ' ' + debouncedSearchValue).trim(),
      }).then((res) => {
        setPagedData((prev) => (prev && pageNumber > 1 ? { ...res, data: [...prev.data, ...res.data] } : res));
        setIsLoading(false);
      });
    },
    [currentClient, debouncedSearchValue, includedIds, selection.excludedIds, selection.filter],
  );

  useEffect(() => {
    if (!open) return;

    loadPage(pageNumber);
  }, [loadPage, open, pageNumber]);

  const loadMore = useCallback(() => {
    if ((pagedData?.data?.length ?? -1) >= (pagedData?.totalCount ?? 0)) return;

    setPageNumber((prev) => prev + 1);
  }, [pagedData?.data?.length, pagedData?.totalCount]);

  useEffect(() => {
    setFileNameInternal(fileName ?? '');
  }, [fileName]);

  const availableModalModes = useMemo(() => {
    if (forceMode) return [forceMode];

    // Cannot export excel version of documents
    if (space === 'document') {
      return Modes.filter((x) => x !== 'excel');
    }

    return Modes;
  }, [forceMode, space]);

  const [modalMode, setModalMode] = useState<ExportModes>(availableModalModes[0]);
  const [modalStep, setModalStep] = useState<ModalStep>('details');

  useEffect(() => {
    setModalMode(availableModalModes[0]);
  }, [availableModalModes]);

  const hideAdvancedSettings = useMemo(
    () => modalMode === 'excel' && !dataExportOptions?.hasAttachments,
    [dataExportOptions?.hasAttachments, modalMode],
  );

  const generatedFileName = useMemo(
    () =>
      t(`export.suggested-filename${space === 'document' ? '-documents' : ''}`, {
        sourceName: `${source.name ? `${source.name}_` : ''}`,
        dateTime: DateUtils.formatDateTime(DateUtils.now, false, true).replaceAll(/[^a-zA-Z0-9\s]/g, ''),
      })
        .replaceAll(/[^a-zA-Z0-9]/g, '_')
        .toLocaleLowerCase(),
    [source.name, space, t],
  );

  const doExcelExport = useCallback(() => {
    if (!client || source.type === undefined) return;

    setLoading(true);

    const failedToast = () =>
      toaster.addToast({
        title: t('export.toasters.failure.title'),
        description: t('export.toasters.failure.description'),
        type: ToastType.ERROR,
      });

    const excludedAttachmentIds = Object.assign({}, ...Object.entries(excludedAttachments.current).map(([key, value]) => ({ [key]: [...value] })));

    DataExportService.createExport({
      includeAll: selectedAll,
      jobSourceType: source.type,
      jobSourceId: source.id,
      filter: {
        ...filter,
        excludedFormIds: excludedIds,
        includedFormIds: selectedAll ? [] : includedIds,
        excludedAttachmentIds: excludedAttachmentIds,
        pageSize: undefined,
        pageNumber: undefined,
      },
      fileName: `${fileNameInternal || generatedFileName}`,
      fileExtension: 'xlsx',
      includeAllReferences: false, // Excel export doesn't export references seperately
      includeAllAttachments: exportAttachments,
      includeCoverPage: false, // Excel export doesn't have a cover page
    })
      .then((res) => {
        toaster.addToast({
          title: t('export.toasters.started.title'),
          description: t('export.toasters.started.description'),
          type: ToastType.INFO,
          expiresInMs: 3000,
          slots: {
            button: (
              <Button
                type={ButtonType.TERTIARY}
                onClick={() => {
                  navigate(`/clients/${client?.id}/organisation#data-exports`);
                }}
              >
                {t('export.toasters.started.button')}
              </Button>
            ),
          },
        });
        onClose();
        listenToProcess<{ fileId: string; fileName: string }>(res.data.jobId)
          .then((res) => {
            FileUtils.downloadFile({ id: res.fileId, name: res.fileName, addExtension: true });
            toaster.addToast({
              title: t('export.toasters.success.title'),
              description: t('export.toasters.success.description'),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
              slots: {
                button: (
                  <div className="flex">
                    <Button
                      type={ButtonType.TERTIARY}
                      onClick={() => {
                        navigate(`/clients/${client?.id}/organisation#data-exports`);
                      }}
                    >
                      {t('export.toasters.success.view-all-button')}
                    </Button>
                  </div>
                ),
              },
            });
          })
          .catch(() => {
            failedToast();
          })
          .finally(() => EventSystem.fireEvent('data-export-done', null));
      })
      .catch(() => {
        failedToast();
      })
      .finally(() => setLoading(false));
  }, [
    client,
    source.type,
    source.id,
    selectedAll,
    filter,
    excludedIds,
    includedIds,
    fileNameInternal,
    generatedFileName,
    exportAttachments,
    toaster,
    t,
    onClose,
    listenToProcess,
    navigate,
  ]);

  const doPdfExport = useCallback(() => {
    if (!client || source.type === undefined) return;

    setLoading(true);

    const failedToast = () =>
      toaster.addToast({
        title: t('export.preparing-error'),
        type: ToastType.ERROR,
      });

    const excludedAttachmentIds = Object.assign({}, ...Object.entries(excludedAttachments.current).map(([key, value]) => ({ [key]: [...value] })));
    const excludedReferenceIds = Object.assign({}, ...Object.entries(excludedReferences.current).map(([key, value]) => ({ [key]: [...value] })));

    DataExportService.createExport({
      includeAll: selectedAll,
      jobSourceType: source.type,
      jobSourceId: source.id,
      filter: {
        ...filter,
        excludedFormIds: excludedIds,
        includedFormIds: selectedAll ? [] : includedIds,
        excludedAttachmentIds,
        excludedReferenceIds,
        pageSize: undefined,
        pageNumber: undefined,
      },
      fileName: `${fileNameInternal || generatedFileName}`,
      fileExtension: 'pdf',
      includeAllReferences: exportReferences,
      includeAllAttachments: exportAttachments,
      includeCoverPage: exportCoverPage,
      includeEvidenceReport: evidenceReport,
      includeDistributionLogs: distributionLogs,
      riskReport: exportRisk
        ? { showOwner: showRiskOwner, statusesToInclude: riskStatuses.filter((x) => x.value).map((x) => x.id as RiskStatus) }
        : undefined,
    })
      .then((res) => {
        toaster.addToast({
          title: t('export.preparing'),
          type: ToastType.INFO,
          expiresInMs: 3000,
        });
        onClose();

        listenToProcess<{ fileId: string; fileName: string }>(res.data.jobId)
          .then((res) => {
            FileUtils.downloadFile({ id: res.fileId, name: res.fileName, addExtension: true });
          })
          .catch(() => {
            failedToast();
          })
          .finally(() => EventSystem.fireEvent('data-export-done', null));
      })
      .catch(() => {
        failedToast();
      })
      .finally(() => setLoading(false));
  }, [
    client,
    distributionLogs,
    evidenceReport,
    excludedIds,
    exportAttachments,
    exportCoverPage,
    exportReferences,
    exportRisk,
    fileNameInternal,
    filter,
    generatedFileName,
    includedIds,
    listenToProcess,
    onClose,
    riskStatuses,
    selectedAll,
    showRiskOwner,
    source.id,
    source.type,
    t,
    toaster,
  ]);

  const doExport = useCallback(() => {
    if (modalMode === 'excel') {
      doExcelExport();
    } else if (modalMode === 'pdf') {
      doPdfExport();
    }
  }, [doExcelExport, doPdfExport, modalMode]);

  const nextStep = useMemo<ModalStep | null>(() => {
    if ((modalStep === 'details' || modalStep === 'settings') && modalMode !== 'excel' && exportReferences && dataExportOptions?.hasReferences) {
      return 'references';
    }

    if (
      (modalStep === 'details' || modalStep === 'settings' || modalStep === 'references') &&
      exportAttachments &&
      dataExportOptions?.hasAttachments
    ) {
      return 'attachments';
    }

    return null;
  }, [dataExportOptions?.hasAttachments, dataExportOptions?.hasReferences, exportAttachments, exportReferences, modalMode, modalStep]);

  const prevStep = useMemo<ModalStep>(() => {
    if (modalStep === 'attachments') return modalMode === 'pdf' && exportReferences ? 'references' : 'details';
    return 'details';
  }, [exportReferences, modalMode, modalStep]);

  const hasNextStep = useMemo(() => !!nextStep, [nextStep]);

  const modalTitle = useMemo(() => {
    switch (modalStep) {
      case 'details':
      default:
        return t('export.heading');
      case 'references':
        return t('export.modal.reference.heading');
      case 'attachments':
        return t('export.modal.attachment.heading');
      case 'settings':
        return t('export.settings-heading');
    }
  }, [modalStep, t]);

  const modalSubTitle = useMemo(() => {
    switch (modalStep) {
      case 'details':
      default:
        return t(`export.subheading-${space}`);
      case 'references':
        return t('export.modal.reference.sub-heading');
      case 'attachments':
        return t('export.modal.attachment.sub-heading');
      case 'settings':
        return '';
    }
  }, [modalStep, space, t]);

  const confirmButtonTitle = useMemo(() => {
    return hasNextStep ? t('export.buttons.next') : t('export.buttons.export');
  }, [hasNextStep, t]);

  const cancelButtonTitle = useMemo(() => {
    if (modalStep === 'settings') return t('export.buttons.back');

    return modalStep === 'details' ? undefined : t('export.buttons.back');
  }, [modalStep, t]);

  const onReferencesSelectionChanged = useCallback((mainId: string, id: string, include: boolean) => {
    excludedReferences.current[mainId] ??= new Set();

    if (include) {
      excludedReferences.current[mainId].delete(id);
    } else {
      excludedReferences.current[mainId].add(id);
    }
  }, []);

  const onAttachmentsSelectionChanged = useCallback((mainId: string, id: string, include: boolean) => {
    excludedAttachments.current[mainId] ??= new Set();

    if (include) {
      excludedAttachments.current[mainId].delete(id);
    } else {
      excludedAttachments.current[mainId].add(id);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setLoadingOptions(true);
      DataExportService.getExportOptions({
        filter: {
          ...filter,
          excludedFormIds: excludedIds,
          includedFormIds: selectedAll ? [] : includedIds,
          pageSize: undefined,
          pageNumber: undefined,
        },
      })
        .then((res) => {
          setDataExportOptions(res.data);
          setEvidenceReport(res.data.hasEvidenceReport);
          setDistributionLogs(res.data.hasDistributions);
          setExportRisk(res.data.hasRisk);

          setExportCoverPage(downloadPreferences ? downloadPreferences.includeCoverPage : true);
          setExportReferences(res.data.hasReferences ? (downloadPreferences ? downloadPreferences.includeReferences : true) : false);
          setExportAttachments(res.data.hasAttachments ? (downloadPreferences ? downloadPreferences.includeAttachments : true) : false);
        })
        .finally(() => setLoadingOptions(false));
    } else {
      setExportCoverPage(true);
      setExportReferences(true);
      setExportAttachments(true);
      setEvidenceReport(true);
      setDistributionLogs(true);
      setModalMode(availableModalModes[0]);
      setModalStep('details');
      excludedAttachments.current = {};
      excludedReferences.current = {};
    }
  }, [availableModalModes, downloadPreferences, excludedIds, filter, includedIds, open, selectedAll]);

  return (
    <ModalContext.Provider value={{ open, onClose, modalWidth: 'xl:w-[800px] w-3/5' }}>
      <StandardModal
        title={modalTitle}
        subTitle={modalSubTitle}
        confirmButtonTitle={confirmButtonTitle}
        onConfirmClick={hasNextStep ? () => setModalStep(nextStep!) : doExport}
        confirmLoading={loading}
        cancelButtonTitle={cancelButtonTitle}
        onCancelClick={modalStep !== 'details' ? () => setModalStep(prevStep) : onClose}
        onTertiaryButtonClick={hideAdvancedSettings ? undefined : () => setModalStep('settings')}
        tertiaryButtonTitle={modalStep === 'settings' ? undefined : t('export.settings-heading')}
        tertiaryButtonIcon={null}
      >
        <PageLoader loading={loadingOptions} loaderSize={16}>
          {modalStep === 'details' && (
            <DataExportChooseDetails
              generatedFileName={generatedFileName}
              fileNameInternal={fileNameInternal}
              setFileNameInternal={setFileNameInternal}
              modes={availableModalModes}
              modalMode={modalMode}
              setModalMode={setModalMode}
            />
          )}
          {modalStep === 'settings' && (
            <DataExportSettings
              modalMode={modalMode}
              exportCoverPage={exportCoverPage}
              setExportCoverPage={setExportCoverPage}
              exportReferences={exportReferences}
              setExportReferences={setExportReferences}
              exportAttachments={exportAttachments}
              setExportAttachments={setExportAttachments}
              evidenceReport={evidenceReport}
              setEvidenceReport={setEvidenceReport}
              distributionLogs={distributionLogs}
              setDistributionLogs={setDistributionLogs}
              exportRisk={exportRisk}
              setExportRisk={setExportRisk}
              showRiskOwner={showRiskOwner}
              setShowRiskOwner={setShowRiskOwner}
              riskStatuses={riskStatuses}
              setRiskStatuses={setRiskStatuses}
              dataExportOptions={dataExportOptions}
            />
          )}
          {modalStep === 'references' && (
            <DataExportChooseReferences
              referencesSelectionChanged={onReferencesSelectionChanged}
              isLoading={isLoading}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              loadMore={loadMore}
              pagedData={pagedData}
            />
          )}
          {modalStep === 'attachments' && (
            <DataExportChooseAttachments
              attachmentsSelectionChanged={onAttachmentsSelectionChanged}
              isLoading={isLoading}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              loadMore={loadMore}
              pagedData={pagedData}
            />
          )}
        </PageLoader>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default DataExportModal;
